@tailwind base;
@tailwind components;
@tailwind utilities;

    @keyframes sendSuccess {
        0% {
            transform: translateX(0);
            opacity: 1;
        }

        50% {
            opacity: 0.7;
            background-color: green;
            color: white;
        }

        100% {
            transform: translateX(100px);
            opacity: 0;
            background-color: green;
            color: white;
        }
    }

    @keyframes sendFail {
        0% {
            transform: translateX(0);
            opacity: 1;
        }

        50% {
            transform: translateX(-10px);
            opacity: 0.5;
            background-color: red;
            color: white;
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    .animation-send-success {
        animation: sendSuccess 0.5s forwards;
    }

    .animation-send-fail {
        animation: sendFail 0.5s;
    }