.navItem:hover .icon {
    opacity: 1;
}

.navItem.active .icon {
    opacity: 1;
}

.dropdown-content {
    background-color: #f1f1f1;
    min-width: 270px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    font-size: 25px;
}

.dropdown-content a:hover {
    background-color: #dddddd;
}

.invisible {
    opacity: 0
}


.nav-logo:hover .normal-logo {
    transform: translate(-90px);
}

.nav-logo:hover .hover-logo {
    opacity: 1;
    transform: translate(-90px);
}

.contact{
    color: #324bd8
}